<template>
  <div class="flex flex-wrap pt-44 wrapper">
    <div class="w-1/6"></div>
    <div class="w-7/12 px-4 pb-32">
      <p class="text-black font-bold head-title leading-none mb-7">Discovery</p>
      <Step2 />
    </div>
    <div class="w-1/5 px-4">
      <ul class="mt-44 border-black border-t">
        <ViewBestPractices />
      </ul>
    </div>
  </div>
</template>

<script>
  import Step2 from '@/components/DiscoveryProcess/Step2'
  import ViewBestPractices from '@/components/ViewBestPractices'
  import { mapGetters } from 'vuex'

  export default {
    name: 'Discovery-Prosess-Step2',
    components: {
      Step2,
      ViewBestPractices
    },
    computed:{
      ...mapGetters({
        seedList: 'discovery/seedlist',
      })
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        if(vm.seedList.length) {
          next()
        }else{
          next('/discovery')
        }
      })
    }
  }
</script>

<style lang="scss" scoped>
.wrapper{
  height: calc(100% + 2.5rem);
}
.discoveryProcessBottom{
  width: calc(100% - 6rem);
  left: 3rem
}
</style>