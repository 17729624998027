<template>
  <div>
    <p class="text-black text-2xl mb-10">
      RadiTube is currently processing your input videos. 
      This can take some time, you can either keep this 
      tab window open or get notified by email when processing has finished.
    </p>
    <ul>
      <li class="mb-6">
        <Loader :animation="true"/>
        <span class="font-sansDemi text-xl text-black">1/3 Retrieving metadata</span>
      </li>
      <li class="mb-6">
        <Loader />
        <span class="font-sansDemi text-xl base-font-gray-light-700">2/3 Looking at user profiles</span>
      </li>
      <li class="mb-6">
        <Loader />
        <span class="font-sansDemi text-xl base-font-gray-light-700">3/3 Performing Analysis</span>
      </li>
    </ul>
    <button 
      @click="checkResults"
      :disabled="disableButton"
      class="font-monoDemi inline-block text-base font-grey-darker-500 leadint-none yellow-bg rounded-2xl px-5 py-2 mt-2"
      :class="{
        'disabled:bg-gray-100 disabled:text-gray-300 disabled:cursor-not-allowed': disableButton
      }"
    >
      Check Results
    </button>
  </div>
</template>

<script>
  import Loader from '@/components/Loader'
import { mapMutations } from 'vuex';

  export default {
    components: {
      Loader
    },
    data(){
      return{
        disableButton: true
      }
    },
    methods: {
      ...mapMutations({
        clearSeedList: 'discovery/clearSeedList'
      }),
      checkResults(){
        window.alert('check Results');
      }
    },
    beforeDestroy(){
      this.clearSeedList()
    }
  }
</script>

<style lang="scss" scoped>

</style>